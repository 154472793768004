<template>
    <div class="sidebar">
        <router-link :to="{ name: 'home' }" class="mb-3 d-block">
            <img
                src="../../assets/icons/infohub-blue-logo-slim.svg"
                alt="infohob-blue-logo"
            />
        </router-link>

        <div class="nav__links">
            <router-link :to="{ name: 'overview' }" class="sidebar__nav-link">
                <div class="flex flex__item-center">
                    <div class="sidebar__nav-icon mr-2">
                        <svg
                            display="flex"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15.5835 9.16683H17.4168C19.2502 9.16683 20.1668 8.25016 20.1668 6.41683V4.5835C20.1668 2.75016 19.2502 1.8335 17.4168 1.8335H15.5835C13.7502 1.8335 12.8335 2.75016 12.8335 4.5835V6.41683C12.8335 8.25016 13.7502 9.16683 15.5835 9.16683Z"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M4.5835 20.1668H6.41683C8.25016 20.1668 9.16683 19.2502 9.16683 17.4168V15.5835C9.16683 13.7502 8.25016 12.8335 6.41683 12.8335H4.5835C2.75016 12.8335 1.8335 13.7502 1.8335 15.5835V17.4168C1.8335 19.2502 2.75016 20.1668 4.5835 20.1668Z"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.50016 9.16683C7.52521 9.16683 9.16683 7.52521 9.16683 5.50016C9.16683 3.47512 7.52521 1.8335 5.50016 1.8335C3.47512 1.8335 1.8335 3.47512 1.8335 5.50016C1.8335 7.52521 3.47512 9.16683 5.50016 9.16683Z"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M16.5002 20.1668C18.5252 20.1668 20.1668 18.5252 20.1668 16.5002C20.1668 14.4751 18.5252 12.8335 16.5002 12.8335C14.4751 12.8335 12.8335 14.4751 12.8335 16.5002C12.8335 18.5252 14.4751 20.1668 16.5002 20.1668Z"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <span class="text">Dashboard</span>
                </div>
            </router-link>
            <router-link
                :to="{ name: 'adminJobSeekers' }"
                class="sidebar__nav-link"
            >
                <div class="flex flex__item-center">
                    <div class="sidebar__nav-icon mr-2">
                        <svg
                            display="flex"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18.0001 7.16C17.9401 7.15 17.8701 7.15 17.8101 7.16C16.4301 7.11 15.3301 5.98 15.3301 4.58C15.3301 3.15 16.4801 2 17.9101 2C19.3401 2 20.4901 3.16 20.4901 4.58C20.4801 5.98 19.3801 7.11 18.0001 7.16Z"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M16.9699 14.4402C18.3399 14.6702 19.8499 14.4302 20.9099 13.7202C22.3199 12.7802 22.3199 11.2402 20.9099 10.3002C19.8399 9.59016 18.3099 9.35016 16.9399 9.59016"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.96998 7.16C6.02998 7.15 6.09998 7.15 6.15998 7.16C7.53998 7.11 8.63998 5.98 8.63998 4.58C8.63998 3.15 7.48998 2 6.05998 2C4.62998 2 3.47998 3.16 3.47998 4.58C3.48998 5.98 4.58998 7.11 5.96998 7.16Z"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M6.99994 14.4402C5.62994 14.6702 4.11994 14.4302 3.05994 13.7202C1.64994 12.7802 1.64994 11.2402 3.05994 10.3002C4.12994 9.59016 5.65994 9.35016 7.02994 9.59016"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12.0001 14.6302C11.9401 14.6202 11.8701 14.6202 11.8101 14.6302C10.4301 14.5802 9.33008 13.4502 9.33008 12.0502C9.33008 10.6202 10.4801 9.47021 11.9101 9.47021C13.3401 9.47021 14.4901 10.6302 14.4901 12.0502C14.4801 13.4502 13.3801 14.5902 12.0001 14.6302Z"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M9.09021 17.7804C7.68021 18.7204 7.68021 20.2603 9.09021 21.2003C10.6902 22.2703 13.3102 22.2703 14.9102 21.2003C16.3202 20.2603 16.3202 18.7204 14.9102 17.7804C13.3202 16.7204 10.6902 16.7204 9.09021 17.7804Z"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <span class="text">Job Seekers</span>
                </div>
            </router-link>
            <router-link :to="{ name: 'home' }" class="sidebar__nav-link">
                <div class="flex flex__item-center">
                    <div class="sidebar__nav-icon mr-2">
                        <svg
                            display="flex"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.16006 10.87C9.06006 10.86 8.94006 10.86 8.83006 10.87C6.45006 10.79 4.56006 8.84 4.56006 6.44C4.56006 3.99 6.54006 2 9.00006 2C11.4501 2 13.4401 3.99 13.4401 6.44C13.4301 8.84 11.5401 10.79 9.16006 10.87Z"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M16.4098 4C18.3498 4 19.9098 5.57 19.9098 7.5C19.9098 9.39 18.4098 10.93 16.5398 11C16.4598 10.99 16.3698 10.99 16.2798 11"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M4.16021 14.56C1.74021 16.18 1.74021 18.82 4.16021 20.43C6.91021 22.27 11.4202 22.27 14.1702 20.43C16.5902 18.81 16.5902 16.17 14.1702 14.56C11.4302 12.73 6.92021 12.73 4.16021 14.56Z"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M18.3398 20C19.0598 19.85 19.7398 19.56 20.2998 19.13C21.8598 17.96 21.8598 16.03 20.2998 14.86C19.7498 14.44 19.0798 14.16 18.3698 14"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <span class="text">Recruiters</span>
                </div>
            </router-link>
            <router-link :to="{ name: 'adminJobs' }" class="sidebar__nav-link">
                <div class="flex flex__item-center">
                    <div class="sidebar__nav-icon mr-2">
                        <svg
                            display="flex"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M22.9301 6.76001L19.5601 20.29C19.3201 21.3 18.4201 22 17.3801 22H4.24013C2.73013 22 1.65015 20.5199 2.10015 19.0699L6.31014 5.55005C6.60014 4.61005 7.47015 3.95996 8.45015 3.95996H20.7501C21.7001 3.95996 22.4901 4.53997 22.8201 5.33997C23.0101 5.76997 23.0501 6.26001 22.9301 6.76001Z"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-miterlimit="10"
                            />
                            <path
                                d="M17 22H21.78C23.07 22 24.08 20.91 23.99 19.62L23 6"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M10.6802 6.38L11.7202 2.06006"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M17.3799 6.38977L18.3199 2.0498"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M8.7002 12H16.7002"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M7.7002 16H15.7002"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <span class="text">Jobs</span>
                </div>
            </router-link>
            <router-link :to="{ name: 'home' }" class="sidebar__nav-link">
                <div class="flex flex__item-center">
                    <div class="sidebar__nav-icon mr-2">
                        <svg
                            display="flex"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                                stroke="#505565"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <span class="text">Settings</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
};
</script>

<style scoped lang="scss">
@import "./_sidebar.scss";
</style>
