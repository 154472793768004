<template>
  <nav class="flex flex__spaced flex__item-center admin__header fs-25 p-3">
    <p class="fw-500 fs-18 use-poppins">Admin</p>
    <div>
      <div
        class="cursor-pointer user__profile--full"
        tabindex="-1"
        @click="showDropdown = !showDropdown"
        @blur="closeDropdown"
      >
        <div class="flex flex__item-center">
          <span class="fs-12 fw-400 use-poppins">Awwalu Adamu</span>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.25 6.75L9 12L3.75 6.75"
              stroke="#333333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="user__profile-options" :class="{ active: showDropdown }">
          <p class="fs-14" @click="logOut">Logout</p>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import avatarIcon from "@/assets/icons/avatar-icon.svg";
import { mapGetters, mapMutations } from "vuex";
import adminService from "../../services/api/adminService";

export default {
  name: "AdminHeader",
  data() {
    return {
      showDropdown: false
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: "auth/getLoggedIn",
      loggedUser: "auth/getLoggedUser"
    }),
    avatarIcon() {
      return this.loggedUser.profilePhoto || avatarIcon;
    }
  },
  methods: {
    ...mapMutations({
      logOut: "auth/logOutAdmin",
      updateLoggedUser: "auth/updateLoggedUser"
    }),
    closeDropdown() {
      this.showDropdown = false;
    }
  },
  mounted() {
    console.log("Admin header mounted");
    if (!this.loggedIn) {
      return;
    }

    adminService
      .getAdminProfile(this.loggedUser.email)
      .then((res) => {
        if (!res.data.error) {
          //   console.log(res.data.data.profile.profilePhoto);
          this.updateLoggedUser({
            profilePhoto: res.data.data.profile.profilePhoto
          });
        }
      })
      .finally(() => {});
  }
};
</script>

<style scoped></style>
