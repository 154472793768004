<template>
    <div class="flex">
        <Sidebar class="slide-in" />
        <main>
            <AdminHeader />
            <div class="p-3">
                <transition name="fade-from-bottom" mode="out-in">
                    <router-view></router-view>
                </transition>
            </div>
        </main>
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import AdminHeader from "@/components/AdminHeader";
import { mapGetters } from "vuex";

export default {
    name: "Overview",
    computed: {
        ...mapGetters({
            loggedUser: "auth/getLoggedUser"
        })
    },
    components: {
        Sidebar,
        AdminHeader
    },
    mounted() {
        // console.log(this.loggedUser);
        if (!this.loggedUser.email) {
            this.$router.push("/admin/login");
        }
    }
};
</script>

<style scoped lang="scss">
@import "./_index.scss";
</style>
